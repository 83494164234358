import { useQuery } from "@tanstack/react-query";
import { Revision, iOrder } from "/app/src/models";
import { revisionService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import Table from "/app/src/components/generic/tables/table";
import { useMemo, useState } from "react";
import { useSortUpgrade } from "/app/src/hooks/useSortUpgrade";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import DateTime from "/app/src/components/generic/formatting/dateTime";

/**
 * Table with the revisions of an order
 */
export default function Revisions({ order }: { order: iOrder }) {
  const { data: revisions, isLoading } = useQuery({
    queryKey: ["revisions", order.id],
    queryFn: () => {
      return revisionService.getAll(buildParams({ orderId: order.id }));
    },
    initialData: { revisions: [] },
    select: (data: { revisions: Revision[] }) => data.revisions,
    refetchInterval: 10000,
  });
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sort, setSort] = useSortUpgrade([]);
  const { data: revisionsCount } = useQuery({
    queryKey: ["revisionsCount", order.id],
    queryFn: () => {
      return revisionService.getCount(buildParams({ orderId: order.id }));
    },
    initialData: { count: 0 },
    select: (data: { count: number }) => {
      return data.count;
    },
    refetchInterval: 10000,
  });

  const columnHelper = createColumnHelper<Revision>();
  const columns = useMemo(
    () => [
      columnHelper.accessor("lineId", {
        id: "lineId",
        cell: (info) =>
          info.getValue() === null ? "Order" : `Line ${info.getValue()}`,
        header: t("translation:type"),
      }),
      columnHelper.accessor("previous", {
        id: "previous",
        cell: (info) => info.getValue(),
        header: t("translation:previous"),
      }),
      columnHelper.accessor("to", {
        id: "to",
        cell: (info) => info.getValue(),
        header: t("translation:to"),
      }),
      columnHelper.accessor("creationDate", {
        id: "creationDate",
        cell: (info) => <DateTime date={info.getValue()} />,
        header: t("translation:date"),
      }),
      columnHelper.accessor("userName", {
        id: "userName",
        cell: (info) => info.getValue(),
        header: t("translation:user"),
      }),
    ],
    [columnHelper, t],
  );
  return (
    <Table
      loading={isLoading}
      rows={revisions}
      tableColumns={columns}
      length={revisionsCount}
      sort={sort}
      setSort={setSort}
      paginationEnabled={{
        currentPage: page,
        pageSize,
        setPage,
        setPageSize,
      }}
      emptyText={t("translation:no_revisions_found")}
    />
  );
}
